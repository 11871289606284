import React, { useState } from "react";
import PropTypes from "prop-types";

function NumberInput({
  fieldName,
  className,
  register,
  validation,
  errors,
  readOnly,
  isRequired,
  maximLength,
  minimLength,
  label,
  mandatory,
  placeHolder,
  defaultValue,
  maxLength,
  setErrors,
}) {
 
  return (
    <div className="form-field">
      <label htmlFor={fieldName}>{label ?? "Number field"}</label>
      {mandatory || isRequired ? (
        <span style={{ color: "red",marginLeft: '3px' }}>*</span>
      ) : (
        <span />
      )}
      <input
        type="text"
        maxLength={maxLength}
        className={className}
        placeholder={placeHolder}
        defaultValue={defaultValue}
        readOnly={readOnly}
        disabled={readOnly}
        onInput={(event) => {
          event.target.value = event.target.value.replace(/[^0-9.]/g, '');
      
          const hasMultipleDecimals = (event.target.value.match(/\./g) || []).length > 1;
          if (hasMultipleDecimals) {
            event.target.value = event.target.value.slice(0, -1);
          }
          // Allow only two digits after the decimal point
          const decimalIndex = event.target.value.indexOf('.');
          if (decimalIndex !== -1) {
            const [integerPart, decimalPart] = event.target.value.split('.');
            if (decimalPart && decimalPart.length > 2) {
              event.target.value = `${integerPart}.${decimalPart.slice(0, 2)}`;
            }
          }
        }}
        {...register(fieldName ?? "Number", {
          validate:validation,
          required: {
            value: isRequired,
            message: `${label} is Required. `,
          },
          maxLength: {
            value: maximLength ?? 10,
            message: `${label} must be maximum ${maximLength ?? 10} characters.`,
          },
          minLength: {
            value: minimLength ?? 2,
            message: `${label} must be minimum ${minimLength ?? 2} characters.`,
          },
        })}
      />
      {errors[fieldName]?.message && (
        <p style={{ color: "red" }}>{errors[fieldName].message}</p>
      )}
    </div>
  );
}

NumberInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  placeHolder: PropTypes.string,
  isRequired: PropTypes.bool,
  maximLength: PropTypes.number,
  minimLength: PropTypes.number,
  label: PropTypes.string,
  mandatory: PropTypes.bool,
};
NumberInput.defaultProps = {
  mandatory: false,
  isRequired: false,
  placeHolder: "Enter Value",
  maximLength: 10,
  minimLength: 0,
  label: "Number Input",
};
export default NumberInput;
