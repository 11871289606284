import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
import TextInput from "../../components/TextField/TextField";
import NumberInput from "../../components/NumberBox/NumberBox";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import { TableContext } from "../../contexts/tableContext";
import "react-toastify/dist/ReactToastify.css";
import styles from "./editMenuPopUp.module.scss";
import { REACT_APP_API_DRINKTYPES } from "../../redux/apiConstants";
import SelectBox from "../../components/SelectBox/SelectBox";
import { REACT_APP_API_ADDONS } from "../../redux/apiConstants";
import { faEdit, faPen } from "@fortawesome/free-solid-svg-icons";
import { getCookies } from "../../hooks/useCookies";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";


const MenuEdit = React.memo(({ menuId, handleClose, setIsUpdated }) => {
  const { setDashboardHeader } = useContext(TableContext);
  const { formthemeStyle, bodyheader, viewformStyle } =
    useContext(TableContext);
  const [menu, setMenu] = useState(null);
  const [error, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [drinkTypes, setDrinkTypes] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [addons, setAddons] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const [addOnData, setAddOnData] = useState([]);
  const [reload, setReload] = useState(false);
  const [description, setDescription] = useState("");
  // const { files } = useContext(TableContext);
  const [files, setMenuFiles] = useState([]);
  const [image, setImage] = useState(null);
  const token = `Bearer ${getCookies("Token")}`;
  const [isPrice, setIsPrice] = useState(false);
  const [unit, setUnit] = useState({
    0: "ml",
    1: "ml",
    2: "ml", 
  });
  const [priceErrors, setPriceErrors] = useState("");
  const [qtyErrors, setQtyErrors] = useState("");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setError,
    clearErrors,
    watch,
  } = useForm();

  const addOnName = watch("addOnName")
  const addOnPrice = watch("addOnPrice")
  let fetchedMenu;


  const [disabled, isDisabled] = useState(false);
  const [smallIndex, setSmallIndex] = useState(-1);
  const [mediumIndex, setMediumIndex] = useState(-1);
  const [largeIndex, setLargeIndex] = useState (-2);

  const getDrinkTypes = async () => {
    const drinktypes = await axios.get(REACT_APP_API_DRINKTYPES);
    // console.log("drinktypes-->", drinktypes.data?.data);
    setDrinkTypes(drinktypes.data?.data);
  };





  const adjustTextareaHeight = (element) => {
    if (element) {
      element.style.height = 'auto'; // Reset height to default
      element.style.height = `${Math.min(element.scrollHeight, 200)}px`; // Set new height with max limit of 200px
    }

  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader("Edit Menu Details");

    const actionData = {
      actionUrl: `menus/menu-details/${menuId}`,
      actionMethod: "get",
    };
    dispatch(Entry(actionData)).then((resp) => {
      if (resp.payload && resp.payload.data && resp.payload.data.menu) {
        fetchedMenu = resp.payload.data.menu;
        if (fetchedMenu?.sizeOptions && fetchedMenu?.sizeOptions.length > 0) {
          const requiredSizes = ['Small', 'Medium', 'Large'];
          const reorderedSizes = requiredSizes.map(size => {
            const foundOption = fetchedMenu.sizeOptions.find(option => option.size === size);
            return (
              foundOption || {
                size: size,
                price: null,
                vendorPrice: null,
                quantity: null,
                unit: '',
              }
            );
          });
          fetchedMenu.sizeOptions = reorderedSizes;

        }
        console.log("data", fetchedMenu);
        if (!fetchedMenu.image || fetchedMenu.image.length === 0) {
          setImage(null);
        } else {
          setImage(fetchedMenu.image);
        }
        // setUnit(fetchedMenu.sizeOptions[0].unit);
        if (fetchedMenu?.sizeOptions) {
          const fetchedUnits = fetchedMenu.sizeOptions.map((option) => option.unit || "ml");
          setUnit(fetchedUnits);
        }
        // if (fetchedMenu.drinkType && fetchedMenu.drinkType.type) {
        //   fetchedMenu.drinkType = fetchedMenu.drinkType.type;
        // }
        setMenu(fetchedMenu);
        setIsPrice(false);
        console.log(resp.payload.data.menu);
        reset(fetchedMenu); // Reset form with fetched data
      } else {
        setErrors("Menu data not found");
      }
    });
    getDrinkTypes();

    // Fetch add-ons based on drink type (menuId) similar to the menu fetch
    const addonsActionData = {
      actionUrl: `addons/addon-bydrink/${menuId}`,
      actionMethod: "get",
    };
    dispatch(Entry(addonsActionData))
      .then((response) => {
        if (response.payload && response.payload.data) {
          setAddons(response.payload.data);
          console.log("Here", addons);
        } else {
          setAddons([]); // No addons found
        }
      })
      .catch((error) => {
        console.error("Error fetching add-ons:", error);
        setAddons([]); // Handle error by showing no addons
      });
  }, [dispatch, menuId, reset, setDashboardHeader, reload]);

  useEffect(() => {
    const textarea = document.getElementById('menudescription');
    if (textarea) {
      adjustTextareaHeight(textarea);
    }
  }, [menu]);




  function validation(fieldIndex) {
    return (value) => {
      const smallPrice = parseFloat(getValues("sizeOptions[0].price"))
      const mediumPrice = parseFloat(getValues("sizeOptions[1].price"))
      const largePrice = parseFloat(getValues("sizeOptions[2].price"))

      if (fieldIndex === 1 && mediumPrice && mediumPrice < smallPrice) {
        setPriceErrors("Medium size price cannot be less than Small size price.");
        return "";
      }

      if (fieldIndex === 2 && largePrice && (largePrice < mediumPrice || largePrice < smallPrice)) {
        setPriceErrors("Large size price cannot be less than Medium or Small size price.");
        return "";
      }

      const otherValues = [
        getValues("sizeOptions[0].price"),
        getValues("sizeOptions[1].price"),
        getValues("sizeOptions[2].price"),
      ];

      // Remove the current field's value from the list of otherValues
      otherValues.splice(fieldIndex, 1);

      // Check if any other field has a value
      if (value || otherValues.some((val) => val)) {
        return true; // Validation passed
      }

      // Return the error message for the first field only
      return fieldIndex === 0 ? "At least one size price is required." : true;
    };
  };

  function quantityValidation(fieldIndex) {
    return (value) => {
      const smallPrice = parseFloat(getValues("sizeOptions[0].price"))
      const mediumPrice = parseFloat(getValues("sizeOptions[1].price"))
      const largePrice = parseFloat(getValues("sizeOptions[2].price"))
      const smallQuantity = parseFloat(getValues("sizeOptions[0].quantity"));
      const mediumQuantity = parseFloat(getValues("sizeOptions[1].quantity"));
      const largeQuantity = parseFloat(getValues("sizeOptions[2].quantity"));

      // Case 1: Medium quantity should not be less than Small quantity
      if (fieldIndex === 1 && mediumQuantity && mediumQuantity < smallQuantity) {
        setQtyErrors("Medium size quantity cannot be less than Small size quantity.");
        return " ";
      }

      // Case 2: Large quantity should not be less than Medium or Small quantity
      if (
        fieldIndex === 2 &&
        largeQuantity &&
        (largeQuantity < mediumQuantity || largeQuantity < smallQuantity)
      ) {
        setQtyErrors("Large size quantity cannot be less than Medium or Small size quantity.");
        return "";
      }

      // Case 3: At least one quantity should be provided
      const otherValues = [
        getValues("sizeOptions[0].quantity"),
        getValues("sizeOptions[1].quantity"),
        getValues("sizeOptions[2].quantity"),
      ];

      if ((smallPrice && !smallQuantity) || (mediumPrice && !mediumQuantity) || (largePrice && !largeQuantity)) {
        setQtyErrors("Quantity is required if a price is set.");
        return "";
      }

      if ((!smallPrice && smallQuantity) || (!mediumPrice && mediumQuantity) || (!largePrice && largeQuantity)) {
        setQtyErrors("Price is required if quantity is specified.");
        return "Price is required if quantity is specified.";
      }

      // Remove the current field's value from the list of otherValues
      otherValues.splice(fieldIndex, 1);

      // Check if any other field has a value or if the current field has a value
      if (value || otherValues.some((val) => val)) {
        return true; // Validation passed
      }

      // Return the error message for the first field only
      return fieldIndex === 0 ? "At least one size quantity is required." : true;
    };
  }


  const handleUnitChange = (event) => {
    const { name, value } = event.target; 
    const index = name.match(/\d+/)[0];
  
    setUnit((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const handleAddonChange = (index, field, value) => {
    const updatedAddons = [...addons];
    updatedAddons[index][field] = value; // Update either name or price in the specific add-on
    setAddons(updatedAddons);
  };

  const handleAddAddon = async () => {
    const addOnName = getValues("addOnName");
    const addOnPrice = parseFloat(getValues("addOnPrice"));

    if (!addOnName || isNaN(addOnPrice)) {
      toast.error("Please enter a valid add-on name and price.");
      return;
    }

    const newAddon = {
      name: addOnName,
      drink: menuId,
      addedBy: "admin",
      price: addOnPrice,
      required: isRequired,
    };

    try {
      // Send the newAddon object to the API endpoint
      const actionUrl = `${REACT_APP_API_ADDONS}/`;
      axios
        .post(
          actionUrl,
          newAddon,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
              // If you need to add cookies manually:
            },
          }
        )
        .then((resp) => {
          if (resp.status === 201) {
            console.log("response-->", resp.data);
            setReload(!reload);
            reset({ addOnName: "", addOnPrice: "" });
            toast.success("Add-on created successfully!");
          } else {
            toast.error("Failed to add the new add-on.");
          }
        })
        .catch((err) => {
          toast.error("Error creating add-on");
          console.error("Error: ", err);
        });
    } catch (error) {
      console.error("Error creating add-on:", error);
      toast.error("Error creating add-on. Please try again.");
    }
  };

  const onSubmitData = (data) => {
    setSubmitting(true);
    setErrors(null);
    const sizeOptions = data.sizeOptions.map((sizeOption, index) => {
      return {
        size: sizeOption.size || ["Small", "Medium", "Large"][index],
        price: isNaN(sizeOption.price) ? 0 : Number(sizeOption.price),
        quantity: sizeOption.quantity,
        unit: unit[index],
        note: sizeOption.note,
      };
    });
    console.log("drinktype", data.drinkType);
    const apiData = {
      name: data.name,
      description: data.description,
      preparationTime: data.preparationTime,
      drinkType: data.drinkType ? data.drinkType._id : menu.drinkType._id,
      taxcode: data.taxcode ? data.taxcode : null,
      sizeOptions: sizeOptions,
      File: files,
      // Addons: addons,
    };

    data.actionUrl = `menus/${menuId}`;
    data.actionMethod = "patch";
    data.apiData = apiData;
    console.log("menudata--->\n", data);
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        toast.success("Menu updated successfully");
        handleClose();
        setIsUpdated(true);
      } else {
        setErrors(resp.payload.message);
        toast.error("Error updating menu");
      }
    });
  };

  // Function to handle deleting an add-on
  const handleDeleteAddon = async (addonId) => {
    if (!addonId) {
      toast.error("Invalid add-on ID.");
      return;
    }

    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this add-on?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const data = {
              actionUrl: `addons/${addonId}`,
              actionMethod: "delete",
            };
            dispatch(Entry(data))
              .then((resp) => {
                if (resp.payload.code === 200) {
                  toast.success("Add-on deleted successfully");
                  setAddons((prevAddons) =>
                    prevAddons.filter((addon) => addon.id !== addonId)
                  );
                } else {
                  setErrors(resp.payload.message);
                  toast.error("Error deleting add-on");
                }
              })
              .catch((error) => {
                console.error("Error deleting add-on:", error);
                toast.error("Error deleting add-on. Please try again.");
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };


  const handleError = (err) => {
    console.log("error", err, "type:", typeof err);
  }

  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <div className="d-flex align-items-center gap-3">
          <button
            className={styles.closeicon}
            aria-label="Close"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {image && (
            <Image src={image} alt="logo" className={styles.profilepic} />
          )}
          <h2 className={styles.heading}>
            {!isEditMode ? "Menu Details" : "Edit Menu Details"}
          </h2>
          {!isEditMode && (
            <button
              className={`${styles.formbtnedit}`}
              onClick={() => setIsEditMode(true)}
              aria-label="Edit"
            >
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: "5px" }} />
              Edit
            </button>
          )}
        </div>
        <div className="container-fluid mt-5 editMenuForm">
          {menu ? (
            <form onSubmit={handleSubmit(onSubmitData)}>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Menu Name"
                    maximLength={30}
                    maxLength={30}
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    readOnly={!isEditMode}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <label htmlFor="description" className={styles.formlabel}>
                    Description
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    id="menudescription"
                    className={`${styles.inputbox}`}
                    placeholder="Enter Description"
                    name = "description"
                    {...register('description')}
                    rows={1}
                    // readOnly={!isEditMode}
                    disabled={!isEditMode}
                    style={{ resize: 'none', overflowY: 'auto' }}
                    onInput={(e) => {
                      e.target.style.height = 'auto';
                      e.target.style.height = `${Math.min(e.target.scrollHeight, 200)}px`;
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <NumberInput
                    className={styles.inputbox}
                    label="Preparation Time (in mins)"
                    fieldName="preparationTime"
                    placeHolder="Preparation Time"
                    register={register}
                    errors={errors}
                    setErrors={setErrors}
                    isRequired
                    mandatory
                    readOnly={!isEditMode}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="drinkType"
                    label="Drink Type"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={drinkTypes}
                    errors={errors}
                    placeholder={menu.drinkType.type}
                    mandatory
                    isRequired
                    Disabled={!isEditMode}
                    readOnly={!isEditMode}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Tax Code"
                    fieldName="taxcode"
                    placeHolder="Enter Tax Code"
                    register={register}
                    errors={errors}
                    readOnly={!isEditMode}
                    maxLength="50"
                    defaultValue={" "}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4 col-sm-4">
                  <NumberInput
                    className={styles.inputbox}
                    label="Small Size Price"
                    fieldName="sizeOptions[0].price"
                    placeHolder="Price"
                    maximLength={6}
                    register={register}
                    errors={errors}
                    validation={validation(0)}
                    // isRequired
                    readOnly={!isEditMode}
                  />
                </div>
                <div className="col-md-4 col-sm-4">
                  <NumberInput
                    className={styles.inputbox}
                    label="Medium Size Price"
                    fieldName="sizeOptions[1].price"
                    placeHolder="Price"
                    maximLength={6}
                    register={register}
                    validation={validation(1)}
                    errors={errors}
                    // isRequired
                    isPrice={isPrice}
                    readOnly={!isEditMode}
                  />
                </div>
                <div className="col-md-4 col-sm-4">
                  <NumberInput
                    className={styles.inputbox}
                    label="Large Size Price"
                    fieldName="sizeOptions[2].price"
                    placeHolder="Price"
                    maximLength={6}
                    register={register}
                    validation={validation(2)}
                    errors={errors}
                    // isRequired
                    readOnly={!isEditMode}
                  />
                </div>
                {priceErrors && (
                  <p className={styles.errormsg}>{priceErrors}</p>
                )}
              </div>

              {/* <div className="row align-items-center">
                <Form.Group className={`d-flex ${styles.biggerRadio} me-3`}>
                  <Form.Check
                    inline
                    type="radio"
                    label="ml"
                    name="unit"
                    value="ml"
                    checked={unit === "ml"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg me-2"
                    disabled={!isEditMode}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="oz"
                    name="unit"
                    value="oz"
                    checked={unit === "oz"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg"
                    disabled={!isEditMode}
                  />
                </Form.Group>
              </div> */}

              <div className="row">
                <div className="col-md-4 col-sm-4">
                  <NumberInput
                    className={styles.inputbox}
                    label="Small Size Quantity"
                    fieldName="sizeOptions[0].quantity"
                    placeHolder={`In ${unit[0]}`}
                    register={register}
                    validation={quantityValidation(0)}
                    maximLength={6}
                    errors={errors}
                    readOnly={!isEditMode}
                  />
                <Form.Group className={`d-flex ${styles.biggerRadio} me-3`}>
                  <Form.Check
                    inline
                    type="radio"
                    label="ml"
                    name="sizeOptions[0].unit"
                    value="ml"
                    checked={unit[0] === "ml"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg me-2"
                    disabled={!isEditMode}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="oz"
                    name="sizeOptions[0].unit"
                    value="oz"
                    checked={unit[0] === "oz"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg"
                    disabled={!isEditMode}
                  />
                </Form.Group>
                </div>
                <div className="col-md-4 col-sm-4">
                  <NumberInput
                    className={styles.inputbox}
                    label="Medium Size Quantity"
                    fieldName="sizeOptions[1].quantity"
                    placeHolder={`In ${unit[1]}`}
                    maximLength={6}
                    register={register}
                    validation={quantityValidation(1)}
                    errors={errors}
                    readOnly={!isEditMode}
                  />
                 <Form.Group className={`d-flex ${styles.biggerRadio} me-3`}>
                  <Form.Check
                    inline
                    type="radio"
                    label="ml"
                    name="sizeOptions[1].unit"
                    value="ml"
                    checked={unit[1] === "ml"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg me-2"
                    disabled={!isEditMode}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="oz"
                    name="sizeOptions[1].unit"
                    value="oz"
                    checked={unit[1] === "oz"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg"
                    disabled={!isEditMode}
                  />
                </Form.Group>
                </div>
                <div className="col-md-4 col-sm-4">
                  <NumberInput
                    className={styles.inputbox}
                    label="Large Size Quantity"
                    fieldName="sizeOptions[2].quantity"
                    placeHolder={`In ${unit[2]}`}
                    validation={quantityValidation(2)}
                    maximLength={6}
                    register={register}
                    errors={errors}
                    readOnly={!isEditMode}
                  />
                <Form.Group className={`d-flex ${styles.biggerRadio} me-3`}>
                  <Form.Check
                    inline
                    type="radio"
                    label="ml"
                    name="sizeOptions[2].unit"
                    value="ml"
                    checked={unit[2] === "ml"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg me-2"
                    disabled={!isEditMode}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="oz"
                    name="sizeOptions[2].unit"
                    value="oz"
                    checked={unit[2] === "oz"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg"
                    disabled={!isEditMode}
                  />
                </Form.Group>
                </div>
                {qtyErrors && (
                  <p className={styles.errormsg}>{qtyErrors}</p>
                )}
              </div>
              {/* notes */}
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="note"
                    fieldName="sizeOptions[0].note"
                    placeHolder="Enter Note for Small Sizes"
                    register={register}
                    errors={errors}
                    readOnly={!isEditMode}
                  // isRequired
                  // mandatory
                  />
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    maximLength={100}
                    label="note"
                    placeHolder="Enter Note for Medium Sizes"
                    fieldName="sizeOptions[1].note"
                    register={register}
                    errors={errors}
                    readOnly={!isEditMode}
                  // isRequired
                  // mandatory
                  />
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    maximLength={100}
                    label="note"
                    placeHolder="Enter Note for Large Sizes"
                    fieldName="sizeOptions[2].note"
                    register={register}
                    errors={errors}
                    readOnly={!isEditMode}
                  // isRequired
                  // mandatory
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <h4 style={{ marginBottom: "20px" }}>Add-ons</h4>
                  {addons.length > 0 ? (
                    addons.map((addon, index) => (
                      <div
                        key={index}
                        className={`${styles.addonRow} row mb-3`}
                      >

                        <div className="col-md- col-sm-3">
                          <span>{addon.name}</span>
                        </div>
                        <div className="col-md-3 col-sm-3">
                          <span>${addon.price.toFixed(2)}</span>
                        </div>
                        <div className="col-md-3 col-sm-3">
                          <span>{addon.required ? "Required" : "Not Required"}</span>
                        </div>
                        <div className={`col-md-3 col-sm-3 text-right ${styles.buttonstyle}`}>
                          {isEditMode && (
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteAddon(addon.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No add-ons available</p>
                  )}
                </div>
              </div>
              {isEditMode && (
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <TextInput
                      className={styles.inputbox}
                      label="Name"
                      fieldName="addOnName"
                      placeHolder="Name"
                      maxLength={40}
                      register={register}
                      errors={errors}
                    // mandatory
                    />
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <NumberInput
                      className={styles.inputbox}
                      name="drinkType"
                      label="Price"
                      labelstyle={styles.formlabel}
                      fieldName="addOnPrice"
                      control={control}
                      register={register}
                      // values={[]}
                      errors={errors}
                      maxLength={8}
                      placeHolder="Enter Price"
                    // mandatory
                    />
                  </div>
                  <div className={`col-lg-3 col-md-3 ${styles.required}`}>
                    <Form.Check
                      className={`${styles.inputboxrequired}`}
                      type="checkbox"
                      label={
                        <span style={{ marginLeft: "5px" }}>Required</span>
                      }
                      checked={isRequired}
                      onChange={() => setIsRequired(!isRequired)} // Toggle the state
                    />
                  </div>
                  <div className={`col-lg-3 col-md-3 ${styles.addonbutton}`}>
                    {isEditMode && (
                      <Button variant="primary" onClick={handleAddAddon}>
                        + Add
                      </Button>
                    )}
                  </div>

                </div>
              )}

              <div className="row">

              </div>
              {errors &&
                Object.keys(errors).map((key) => {
                  const value = errors[key];
                  if (
                    key === "sizeOptions" &&
                    Array.isArray(value) &&
                    value.every(
                      (item) => typeof item === "object" && item !== null
                    )
                  ) {
                    return (
                      <div key={key}>
                        {value.map((sizeOptionsObject, index) => (
                          <div key={`${key}-${index}`}>
                            {/* Optional header for clarity */}
                            {/* <strong className={styles.errormsg}>{key} - Object {index + 1}:</strong> */}
                            {Object.keys(sizeOptionsObject).map((nestedKey) => (
                              <p key={nestedKey} className={styles.errormsg}>
                                {sizeOptionsObject[nestedKey]?.message || 
                                  null}
                              </p>
                            ))}
                          </div>
                        ))}
                      </div>
                    );
                  }

                  if (typeof value === "object" && value !== null) {
                    return (
                      <div key={key}>
                        {/* <strong className={styles.errormsg}>{key}:</strong> */}
                        {value.message ? (
                          <p className={styles.errormsg}>{value.message}</p>
                        ) : (
                          Object.keys(value).map((nestedKey) => (
                            <p key={nestedKey} className={styles.errormsg}>
                              {nestedKey}: {value[nestedKey]}
                            </p>
                          ))
                        )}
                      </div>
                    );
                  }

                  return (
                    <p key={key} style={{ color: "red" }}>
                      {value}
                    </p>
                  );
                })}

              {isEditMode && (
                <div className="row mt-3">
                  <div className="col-md-12 col-sm-12">
                    <MultipleFileUpload
                      section="menu"
                      disabled={!isEditMode}
                      setMenuFiles={setMenuFiles}
                      register={register}
                      errors={errors}
                    />
                  </div>
                </div>
              )}
              {isEditMode && (
                <div className={styles.btnContainer}>
                  <input
                    className={`${styles.formbtnupdate}`}
                    type="submit"
                    disabled={submitting || addOnName || addOnPrice}
                    value={submitting ? "Please wait.." : "Update"}
                  />
                </div>
              )}
              {error && <h6 className={styles.errormsg}>{error}</h6>}
            </form>
          ) : (
            <div
              style={{
                textAlign: "center",
                marginTop: "130px",
              }}
            >
              <SpinnerDotted
                size={90}
                thickness={180}
                speed={100}
                color="#f4ae01"
              />
              <h4 className="mt-3">Fetching data, please wait...</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default MenuEdit;
