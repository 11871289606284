/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import axios from "axios";
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  Suspense,
  lazy,
} from "react";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import PropTypes, { bool } from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { TableContext } from "../../contexts/tableContext";
// import { SpinnerRoundFilled } from 'spinners-react';
import ActionButtons from "../ActionsButtons/ActionButtons";
import Pagination from "../Pagination/Pagination";
import { tabledelete } from "../../redux/TableDeleteSlice";
import ToggleButtonOnOff from "../ToggleButton/ToggleButton";
// import ActionSelection from '../ActionsButtons/ActionSelection';
import styles from "./CustomTable.module.scss";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getCookies } from "../../hooks/useCookies";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import { SpinnerDotted } from "spinners-react";
import { NumFormatter } from "../../utilityFunctions/utilsFunctions";
import { Image } from "react-bootstrap";
import { Entry } from "../../redux/entrySlice";
import { toast } from "react-toastify";
import useToggle from "../../hooks/useToggle";
import CreateVendorPopup from "../../pages/vendors/createVendorPopUp";
import CreateCafePopup from "../../pages/cafes/createCafePopUp";
import CreateDrinkPopup from "../../pages/drinktypes/createDrinkPopUp";
import CreateMenuPopup from "../../pages/menus/createMenuPopup";
import { REACT_APP_API_FETCH_CLAIM_REQUESTS } from "../../redux/apiConstants";
import CreateCouponPopup from "../../pages/coupons/createCouponPopup";

const Counter = lazy(() => import("../../components/Counter/Counter"));

const CustomTable = React.memo(
  ({
    urlParam,
    viewPopUp,
    paramId,
    toggleButton,
    section,
    deleteCondition,
  }) => {
    let url;
    const { dashboardStyle, card1bgStyle, card2bgStyle, card3bgStyle } =
      useContext(TableContext);
    const navigate = useNavigate();
    const { bodyStyle } = useContext(TableContext);
    const [addButtonPermissions, setAddButtonPermissions] = useState({});
    const { bodyheader } = useContext(TableContext);
    const { setDashboardHeader } = useContext(TableContext);
    const { searchboxStyle } = useContext(TableContext);
    const { dashUpdate, setDashUpdate } = useContext(TableContext);
    const { tableheadStyle } = useContext(TableContext);
    const { tablebodyStyle } = useContext(TableContext);
    const { actionbtnStyle } = useContext(TableContext);
    const { claimUpdate, setClaimUpdate } = useContext(TableContext);
    const [tableData, setTableData] = useState([]);
    const [cafeStatus, setCafeStatus] = useState("");
    const [showTablePopUp, setShowTablePopUp] = useToggle();
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();
    // const { pageNum, setPageNum } = useContext(TableContext);
    // new
    const [pageNum, setPageNum] = useState(1);
    const tablelength = 0;
    const [checkedState, setCheckedState] = useState(
      new Array(tablelength).fill(false)
    );
    const [checkedAll, setCheckedAll] = useState(false);
    const token = `Bearer ${getCookies("Token")}`;
    const dateFormat = getCookies("dateFormat");
    const [sorting, setSorting] = useState([{ col: "none", sortType: "none" }]);
    const [paginationData, setPaginationData] = useState([
      { page: 0, totalResult: 0, totalPages: 0 },
    ]);
    const [search, setSearchTerm] = useState(null);
    const [timer, setTimer] = useState(null);
    const [columns, setColumns] = useState([]);
    const [deletedData, setDeletedData] = useState(null);
    const [selected, setselected] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [totalResults, setTotalResults] = useState(null);
    const [canDelete, setCanDelete] = useState(true);
    const [actionReject, setActionReject] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [searchState, setSearchState] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const params = useParams();
    const [claimCafeId, setClaimCafeId] = useState(null);
    // const [queryParam,setqueryParam] = useState(null);
    let queryParam;
    // const dataPerPage = section === "Menus" ? 1000 : 5;
    const dataPerPage = 5;
    let response;
    let statusCode;
    const handleOnChange = (id, position) => {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line max-len
      const updatedCheckedState = checkedState.map((item, indexed) =>
        indexed === position ? !item : item
      );
      setCheckedState(updatedCheckedState);
      setselected((oldArray) => [...oldArray, id]);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= checkedState.length; i++) {
        if (checkedState[position] === true) {
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < selected.length; j++) {
            if (selected[j] === id) {
              selected.splice(j, 1);
            }
            // const newselected = selected.filter((item) => item === id);
            // setselected(newselected);
          }
        }
      }
    };

    const capitalizeWords = (sentence) => {
      return sentence
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    };

    const actionAdd = () => {
      console.log("section----", section);
      if (section === "Menus" || section === "coupons") {
        setShowTablePopUp(true);
        // navigate(`/${section.toLowerCase()}/create/${paramId}`);
      } else if (
        section === "Vendor" ||
        section === "Cafe" ||
        (section === "drinktypes") | (section === "Menus")
      ) {
        setShowTablePopUp(true);
      } else {
        // setShowTablePopUp(true);
        navigate(`/${section.toLowerCase()}/create/`);
      }
    };

    const handleOnChangeAll = () => {
      setCheckedAll(!checkedAll);
      const newarray = [];
      if (checkedAll === false) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    };

    const getUsers = async () => {
      setTableLoading(true);
      url = urlParam;
      url += `page=${pageNum}&limit=${dataPerPage}`;
      if (sorting.sortType && sorting.sortType !== "none") {
        url += `&sortBy=${sorting.col}:${sorting.sortType}`;
      }
      if (search !== null) {
        url += `&searchBy=${search}`;
        setSearchState(true);
      }
      if (queryParam && cafeStatus === "") {
        url += `&statusBy=${queryParam}`;
        applyFilter(queryParam);
      }
      if (cafeStatus !== "" && section === "Cafe") {
        url += `&statusBy=${cafeStatus}`;
      }
      console.log(url);
      axios
        .get(url, {
          method: "GET",
          headers: { Authorization: token },
        })
        .then((resp) => {
          setTableLoading(false);
          statusCode = resp.status;
          return resp;
        })
        .then((res) => {
          if (statusCode === 200 || statusCode === 201) {
            console.log("res", res)
            response = res.data.data.result;
            console.log("custom table response---", response);
            setTableData(response.results);
            setClaimCafeId(response.claimCafeId ?? null);
            setPendingCount(response.pendingCount);
            setPaginationData({
              page: response.page,
              totalResult: response.totalResults,
              totalPages: response.totalPages,
            });
            setTotalResults(response?.totalResults);
            if (
              response?.totalResults === 0 ||
              response?.results.length === 0
            ) {
              setColumns([]);
            } else {
              setColumns(response?.Displayfields);
            }
          }
        })
        .catch((err) => {
          setTableLoading(false);
          console.log("error:", err);
          if (err.response.status === 404) {
            console.log("axios errors")
            setColumns([]);
            setTableData([]);
          }
          return err;
        });
    };

    const location = useLocation();

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const query = searchParams.get("statusBy");
      console.log("query: " + query);
      queryParam = query;
      if (query) {
        searchParams.delete("statusBy");
        navigate(
          { pathname: location.pathname, search: searchParams.toString() },
          { replace: true }
        );
      }
    }, [location, navigate]);

    useEffect(() => {
      setSearchTerm(null);
      setPageNum(1);
    }, [cafeStatus]);

    useEffect(() => {
      setDashboardHeader(
        section === "payoutaccounts"
          ? "Payout Accounts"
          : section === "payoutrequests"
            ? "Payout Requests"
            : section === "drinktypes"
              ? "Drink Types"
              : section === "claimrequests"
                ? "Cafe Claim Requests"
                : section === "enquiries"
                  ? "Enquiries"
                  : section === "Menus"
                    ? "Cafe"
                    : section === "coupons"
                      ? "Coupons"
                      : section === "claimRequestDetails"
                        ? "Cafe Claim Requests"
                        : section
      );
      const temp = section.replace(/\s+/g, "").toLowerCase();
      const permissions = getCookies("USERPERMISSION");
      console.log("permissions", permissions);
      permissions.forEach((val) => {
        if (val.section.toLowerCase() === temp) {
          setAddButtonPermissions({
            view: val.view ? val.view : false,
            edit: val.edit ? val.edit : false,
            create: val.create ? val.create : false,
            delete: val.delete ? val.delete : false,
            list: !!(val.view || val.edit || val.create || val.delete),
          });
        } else if (val.submenu) {
          val.submenu.forEach((element) => {
            if (element.section.toLowerCase() === temp) {
              setAddButtonPermissions({
                view: element.view ? element.view : false,
                edit: element.edit ? element.edit : false,
                create: element.create ? element.create : false,
                delete: element.delete ? element.delete : false,
                list: !!(
                  element.view ||
                  element.edit ||
                  element.create ||
                  element.delete
                ),
              });
            }
          });
        }
      });
      getUsers();
      const newarray = [];
      if (checkedAll) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
      if (isUpdated) {
        setIsUpdated(false);
      }
      if (claimUpdate) {
        setClaimUpdate(false);
      }
    }, [pageNum, search, actionReject, cafeStatus, queryParam, isUpdated, claimUpdate]);

    const setSort = (param) => () => {
      setPageNum(1);
      switch (sorting.sortType) {
        case "none":
          setSorting({ col: param, sortType: "asc" });
          break;
        case "asc":
          setSorting({ col: param, sortType: "desc" });
          break;
        case "desc":
          setSorting({ col: param, sortType: "none" });
          break;
        default:
          setSorting({ col: param, sortType: "asc" });
          break;
      }
    };
    const onFilterTextChange = (e) => {
      const even = e;
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          const { value } = even.target;
          if (value.length > 0) {
            setPageNum(1);
            setSearchTerm(value.trimStart());
          } else {
            setSearchTerm(null);
          }
        }, 1000)
      );
    };

    const actionView = (param) => {
      console.log(section, "param");
      const encodeText = param;
      if (viewPopUp) {
        setShowTablePopUp(true);
      } else if (section !== "Notifications")
        navigate(`/${section}/viewdetails/${encodeText}`);
      else if (section === "Menus") {
        navigate(`/${section.toLowerCase()}/menu-details/${paramId}`);
      }
    };

    const handleReject = async (id) => {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure you want to reject the cafe request?",
        buttons: canDelete
          ? [
            {
              label: "Yes",
              onClick: () => {
                const actionData = {};
                actionData.actionUrl = `cafe/reject-cafe/${id}`;
                actionData.actionMethod = "post";
                dispatch(Entry(actionData))
                  .then(() => {
                    setActionReject((prev) => prev + 1);
                    toast.success("Cafe request rejected successfully");
                    if (!dashUpdate) {
                      setDashUpdate(true);
                    }
                    setIsUpdated(true);
                  })
                  .catch((err) => {
                    toast.error("Error rejecting cafe request");
                    console.error("Error: ", err);
                  });
              },
            },
            {
              label: "No",
              // onClick: () => alert("Click No")
            },
          ]
          : [
            {
              label: "Ok",
              // onClick: () => alert("Click No")
            },
          ],
      });
    };
    const handleClaimApprove = (id) => {
      console.log("approve id", claimCafeId);
      let data = {};

      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure you want to approve?",
        buttons: canDelete
          ? [
            {
              label: "Yes",
              onClick: () => {
                const actionUrl = ` ${REACT_APP_API_FETCH_CLAIM_REQUESTS}/approve/${id} `;
                const actionData = { cafeId: claimCafeId };
                axios
                  .post(actionUrl, actionData, {
                    headers: { Authorization: token },
                  })
                  .then((resp) => {
                    if (resp.status === 200) {
                      console.log("response-->", resp.data);
                      toast.success("Claim Request approved successfully");
                      if (!claimUpdate) setClaimUpdate(true);
                      setShowTablePopUp(false);
                      setDashUpdate(prevDashUpdate => !prevDashUpdate);
                      setIsUpdated(true);
                    } else {
                      toast.error("Error approving Claim Request");
                    }
                  })
                  .catch((err) => {
                    toast.error("Error approving Claim Request");
                    console.error("Error: ", err);
                  });
              },
            },
            {
              label: "No",
              // onClick: () => alert("Click No")
            },
          ]
          : [
            {
              label: "Ok",
              // onClick: () => alert("Click No")
            },
          ],
      });
    };

    const handleClaimReject = (id) => {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure you want to reject?",
        buttons: canDelete
          ? [
            {
              label: "Yes",
              onClick: () => {
                console.log("ids:", id, "sldfj:", claimCafeId)
                const actionUrl = ` ${REACT_APP_API_FETCH_CLAIM_REQUESTS}/reject/${id} `;
                const actionData = { cafeId: claimCafeId };
                axios
                  .post(actionUrl, actionData, {
                    headers: { Authorization: token },
                  })
                  .then((resp) => {
                    if (resp.status === 200) {
                      console.log("response-->", resp.data);
                      toast.success("Claim Request rejected successfully");
                      setShowTablePopUp(false);
                      if (!claimUpdate) setClaimUpdate(true);
                      setDashUpdate(prevDashUpdate => !prevDashUpdate);
                      setIsUpdated(true);
                    } else {
                      toast.error("Error rejecting Claim Request");
                    }
                  })
                  .catch((err) => {
                    toast.error("Error rejecting Claim Request");
                    console.error("Error: ", err);
                  });
              },
            },
            {
              label: "No",
              // onClick: () => alert("Click No")
            },
          ]
          : [
            {
              label: "Ok",
              // onClick: () => alert("Click No")
            },
          ],
      });
    };
    const handleApprove = async (id) => {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure you want to approve the cafe request?",
        buttons: canDelete
          ? [
            {
              label: "Yes",
              onClick: () => {
                const actionData = {};
                actionData.actionUrl = `cafe/approve-cafe/${id}`;
                actionData.actionMethod = "post";
                dispatch(Entry(actionData))
                  .then(() => {
                    setActionReject((prev) => prev + 1);
                    toast.success("Cafe request approved successfully");
                    setIsUpdated(true);
                    if (!dashUpdate) {
                      setDashUpdate(true);
                    }
                  })
                  .catch((err) => {
                    toast.error("Error approving cafe request: ");
                    console.error("Error: ", err);
                  });
              },
            },
            {
              label: "No",
              // onClick: () => alert("Click No")
            },
          ]
          : [
            {
              label: "Ok",
              // onClick: () => alert("Click No")
            },
          ],
      });
    };


    const applyFilter = (status) => {
      setCafeStatus(status);
    };

    return (
      <div className={styles[bodyStyle]}>
        {addButtonPermissions.list && (
          <div
            className={styles[dashboardStyle]}
            style={{ minHeight: section !== "Menus" ? "100vh" : null }}
            id={viewPopUp ? styles.dashboardcont3 : styles.dashboardcont2}
          >
            {/* {(section === "User" ||
              section === "Vendor" ||
              section === "Cafe") && (
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "20px",
                          marginLeft: "120px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <Counter
                    className={section === "User"
                      ? styles[card1bgStyle] // Replace with the appropriate icon for "User"
                      : section === "Vendor"
                        ? styles[card2bgStyle] // Replace with the appropriate icon for "User"
                        // Replace with the appropriate icon for "Vendor"
                        : section === "Cafe"
                          ? styles[card3bgStyle] // Replace with the appropriate icon for "User"
                          // Replace with the appropriate icon for "Cafe"
                          : styles[card1bgStyle] // Replace with the appropriate icon for "User"
                      // Default icon for "Total Results" or other sections
                    }
                    cardstyle={styles.cardbox1}
                    cardicon={
                      section === "User"
                        ? faUsers // Replace with the appropriate icon for "User"
                        : section === "Vendor"
                          ? faStore // Replace with the appropriate icon for "Vendor"
                          : section === "Cafe"
                            ? faCoffee // Replace with the appropriate icon for "Cafe"
                            : faBookOpenReader // Default icon for "Total Results" or other sections
                    }
                    text={
                      section === "User"
                        ? "Users"
                        : section === "Vendor"
                          ? "Vendors"
                          : section === "Cafe"
                            ? "Cafes"
                            : "Total Results"
                    }
                    count={NumFormatter(totalResults)}
                  />
                </Suspense>
              )} */}
            <div className="container-fluid" id={styles.container}>
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {(((!tableLoading && tableData?.length > 0) || searchState) && section !== "claimRequestDetails") && (
                    <div className={styles.search_box}>
                      <input
                        className={styles[searchboxStyle]}
                        id={styles.searchbar}
                        type="search"
                        onChange={onFilterTextChange}
                        placeholder="Search"
                        readOnly={tableLoading}
                      />
                    </div>
                  )}
                  {!tableLoading ? (
                    Object.keys(tableData)?.length > 0 ? (
                      <div
                        className={
                          tableLoading ? "spinner-border spinner-border-sm" : ""
                        }
                        id={styles.spinner}
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <h6 className="mt-4">
                        {searchState
                          ? "No results found."
                          : section === "Menus"
                            ? "No menus available."
                            : section === "Vendor"
                              ? "No vendors are available at the moment."
                              : section === "User"
                                ? "No users found in this section."
                                : section === "Cafe"
                                  ? cafeStatus === "Pending"
                                    ? "No Pending Cafe Requests."
                                    : cafeStatus === "Rejected"
                                      ? "No Cafes Rejected."
                                      : cafeStatus === "Approved"
                                        ? "No Cafes Approved."
                                        : "No cafes have been added yet."
                                  : section === "coupons"
                                    ? "No coupons have been added."
                                    : section === "claimrequests"
                                      ? "No claim requests have been submitted."
                                      : section === "Feedback"
                                        ? "No feedbacks have been submitted yet."
                                        : section === "payoutrequests"
                                          ? "No payout requests have been made."
                                          : section === "drinktypes"
                                            ? "No drink types available."
                                            : section === "enquiries"
                                              ? "No enquiries have been submitted."
                                              : section === "claimRequestDetails"
                                                ? "No claim requests."
                                                : "This section is currently empty."}
                      </h6>
                    )
                  ) : (
                    section !== "Menus" && (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                      // <></>
                    )
                  )}
                </div>
                <div
                  className={`col-lg-3 col-md-12 col-sm-12 ${styles.pendingmessage}`}
                >
                  {pendingCount > 0 && (
                    <div
                      style={{
                        backgroundColor: "#FF5C00",
                        color: "white",
                        padding: "5px",
                        cursor: "pointer",
                        borderRadius: "10px",
                      }}
                      onClick={() => applyFilter("Pending")}
                    >
                      {pendingCount} Cafes Pending, click here or select from
                      dropdown to filter
                      <i
                        className="fa fa-chevron-right"
                        style={{ marginLeft: "5px" }}
                      ></i>
                    </div>
                  )}
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className={styles.sectionright}>
                    {addButtonPermissions.delete && selected.length > 0 && (
                      <div className="dropdown" id={styles.dropdown}>
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <ul
                          className="dropdown-menu"
                          id={styles.dropdown_menu}
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                confirmAlert({
                                  title: "",
                                  message: "Are you sure you want to delete?",
                                  buttons: [
                                    {
                                      label: "Yes",
                                      onClick: () => {
                                        dispatch(tabledelete(selected)).then(
                                          () => {
                                            setDeletedData(
                                              `data deleted ${selected}`
                                            );
                                            window.location.reload(false);
                                          }
                                        );
                                      },
                                    },
                                    {
                                      label: "No",
                                      // onClick: () => alert("Click No")
                                    },
                                  ],
                                });
                              }}
                            >
                              Delete{" "}
                              <i
                                className="fa fa-trash"
                                aria-hidden="true"
                                id={styles.del_icon}
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                    {/* {addButtonPermissions.delete && (
                    <button
                      className={selected.length ? styles.tabledelbtn : 'disabled'}
                      type="button"
                      onClick={() => {
                        confirmAlert({
                          title: '',
                          message: 'Are you sure want to delete.',
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                dispatch(tabledelete(selected)).then(() => {
                                  setDeletedData(`data deleted ${selected}`);
                                  window.location.reload(false);
                                });
                              },
                            },
                            {
                              label: 'No',
                              // onClick: () => alert("Click No")
                            },
                          ],
                        });
                      }}
                    >
                      <i className="fa fa-trash" aria-hidden="true" id={styles.del_icon} />
                    </button>
                  )} */}

                    {section === "Cafe" && (
                      <>
                        {/* <span>Filter</span> */}

                        <select
                          className={styles.filterDropdown}
                          onChange={(e) => applyFilter(e.target.value)}
                          value={cafeStatus}
                        >
                          <option value="">All</option>
                          <option value="Pending">Pending</option>
                          <option value="Rejected">Rejected</option>
                          <option value="Approved">Approved</option>
                          <option value="Claimed">Claimed</option>

                          <option value="Unclaimed">Unclaimed</option>
                          {/* Add more options as needed */}
                        </select>
                      </>
                    )}
                    {addButtonPermissions.create && section !== "User" && (
                      <button
                        className={styles.tablebtn}
                        type="button"
                        onClick={() => {
                          actionAdd();
                        }}
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={styles.add_icon}
                        />
                        {"  "}
                        {section === "Menus"
                          ? "Add Menu"
                          : section === "Vendor"
                            ? "Add Vendor"
                            : section === "User"
                              ? "Add User"
                              : section === "Cafe"
                                ? "Add Cafe"
                                : section === "drinktypes"
                                  ? "Add Drink Type"
                                  : section === "coupons"
                                    ? "Add Coupon"
                                    : "Add"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="table-responsive" style={{ minHeight: "250px" }}>
                <table className="table table-borderless">
                  {columns?.length > 0 && (
                    <thead>
                      <tr className={styles[tableheadStyle]}>
                        {/* {addButtonPermissions.delete && (
                          <th scope="col">
                            <input
                              type="checkbox"
                              id="custom-checkbox"
                              // name={data.id}
                              // value={data.id}
                              checked={checkedAll}
                              onChange={() => handleOnChangeAll()}
                            />
                          </th>
                        )} */}
                        {columns.map((item) => (
                          <th scope="col">
                            {" "}
                            {item === "bankName"
                              ? "Bank Name"
                              : item === "bankAccountNumber"
                                ? "Account Number"
                                : item === "adminApproved"
                                  ? "Admin Approved"
                                  : item === "requestedTime"
                                    ? "Requested Time"
                                    : item === "requestDate"
                                      ? "Requested Date"
                                      : item === "location_name"
                                        ? "Location"
                                        : item === "claimrequests"
                                          ? "Claim Requests"
                                          : item === "totalEmployees"
                                            ? "Total Employees"
                                            : item === "totalRating"
                                              ? "Toatal rating"
                                              : item === "userType"
                                                ? "User Type"
                                                : item === "firstName"
                                                  ? "First Name"
                                                  : item === "lastName"
                                                    ? "Last Name"
                                                    : item === "payoutStatus"
                                                      ? "Payout Status"
                                                      : item === "couponCode"
                                                        ? "Coupon Code"
                                                        : capitalizeWords(item)}
                            {/* <button
                              className={styles.sortbtn}
                              type="button"
                              aria-label="Sort button"
                              onClick={setSort(item)}
                            >
                              {sorting.sortType === "asc" &&
                              sorting.col === item ? (
                                <i className="fa fa-arrow-up" />
                              ) : sorting.sortType === "desc" &&
                                sorting.col === item ? (
                                <i className="fa fa-arrow-down" />
                              ) : (
                                <i className="fa fa-sort" />
                              )}
                            </button> */}
                          </th>
                        ))}
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                  )}
                  {Object?.keys(tableData) && !tableLoading ? (
                    <tbody>
                      {tableData?.map((item, index) => (
                        <tr
                          className={styles[tablebodyStyle]}
                          style={
                            item?.cafeStatus === "Pending"
                              ? { backgroundColor: "#FFEBCC" }
                              : null
                          }
                        >
                          {/* {addButtonPermissions.delete && (
                            <th scope="row">
                              <div className="left-section">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${item}`}
                                  name={item.id}
                                  value={item.id}
                                  checked={checkedState[
                                  
                                  index]}
                                  onChange={() =>
                                    handleOnChange(item.id, index)
                                  }
                                />
                              </div>
                            </th>
                          )} */}
                          {columns?.map((ele) => (
                            <td >
                              {ele === toggleButton.field ? (
                                <ToggleButtonOnOff
                                  value={item[ele]}
                                  id={item.id}
                                  placeholders={["Active", "Inactive"]}
                                />
                              ) : ele === "adminApproved" ? (
                                <ToggleButtonOnOff
                                  value={item.adminApproved}
                                  id={item.id}
                                  placeholders={["Active", "Inactive"]}
                                  isDisabled={true}
                                />
                              ) : typeof item[ele] === "boolean" ? (
                                <h6 className="order_date">
                                  {" "}
                                  String(item[ele])
                                </h6>
                              ) : ele === "username" ? (
                                item?.user?.name
                              ) : ele === "posted date" ? (
                                DateFormatter(
                                  item?.createdAt,
                                  dateFormat || "MonthDayYear"
                                )
                              ) : ele === "requestedTime" ? (
                                DateFormatter(
                                  item?.requestedTime,
                                  dateFormat || "MonthDayYear"
                                )
                              ) : ele === "requestDate" ? (
                                DateFormatter(
                                  item?.requestDate,
                                  dateFormat || "MonthDayYear"
                                )
                              ) : ele === "vendor " ? (
                                item?.vendor?._id ? (
                                  item?.vendor?.name ? (
                                    item?.vendor?.name
                                  ) : (
                                    item?.vendor?.phone
                                  )
                                ) : (
                                  <span style={{ color: "orange" }}>
                                    Not Claimed
                                  </span>
                                )
                              ) : ele === "vendor name" ? (
                                item?.userId?.name
                              ) : ele === "name" ? (
                                item?.userId?.name ?? item.name
                              ) : ele === "discount" ? (
                                `${item.discount}%`
                              ) : ele === "email" ? (
                                item?.userId?.email ?? item.email
                              ) : ele === "phone" ? (
                                item?.userId?.phone ?? item.phone
                              ) : ele === "image" ? (
                                item[ele]?.length > 0 ? (
                                  <Image
                                    src={item[ele]}
                                    alt="logo"
                                    style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                  />
                                ) : (
                                  "N/A"
                                )
                              ) : (
                                item[ele]
                              )}
                            </td>
                          ))}






                          <td className={styles.btnLayout}>
                            <ActionButtons
                              data={item}
                              claimCafeId={claimCafeId}
                              viewPopUp={true}
                              section={section.toLowerCase()}
                              setTableData={setTableData}
                              setIsUpdated={setIsUpdated}
                            />

                            {/* {section === "Cafe" && (
                              <span
                                style={{ cursor: "pointer" }}
                                className="py-3"
                                onClick={() => navigate(`/menu/${item.id}`)}
                              >
                                <button
                                  type="button"
                                  className={styles.tablebtn1}
                                >
                                  <FontAwesomeIcon
                                    icon={faReceipt}
                                    className={styles.add_icon}
                                  />
                                </button>
                              </span>
                            )} */}
                            {section === "claimRequestDetails" &&
                              <div className={styles.cafeapproval}>
                                <button
                                  className={`${styles.actionbtn} btn btn-outline-success btn-sm mx-3`}
                                  onClick={() => handleClaimApprove(item.userId?.id)}
                                >
                                  <i className="fa fa-check"></i>
                                </button>
                                <button
                                  className="btn btn-outline-danger btn-sm"

                                  onClick={() => handleClaimReject(item.userId?.id)}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              </div>
                            }
                            {section === "Cafe" &&
                              item?.cafeStatus === "Pending" && (
                                <div>
                                  {/* <button
                                    className={styles[actionbtnStyle]}
                                    // id={styles.actionbtn}
                                    style={{
                                      marginRight: "5px",
                                      color: "green",
                                    }}
                                    onClick={() => handleApprove(item.id)}
                                  >
                                    <i className="fa fa-check"></i>
                                  </button> */}
                                  <button
                                    className={`${styles.actionbtn} btn btn-outline-success btn-sm mx-3`}
                                    onClick={() => handleApprove(item._id)}
                                  >
                                    <i className="fa fa-check"></i>
                                  </button>
                                  <button
                                    className="btn btn-outline-danger btn-sm"
                                    // id={styles.actionbtn}
                                    onClick={() => handleReject(item._id)}
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>
                                </div>
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    section !== "Menus" && <h6>Loading...</h6>
                  )}
                  {/* {tableData.length === 0 && !tableLoading ? (
                    <h6 className={styles.norecord}>No record found</h6>
                  ) : (
                    ""
                  )} */}
                </table>
              </div>
              {paginationData?.totalPages > 1 && (
                <Pagination
                  paginationData={paginationData}
                  setPageNum={setPageNum}
                  section={section.toLowerCase()}
                />
              )}
              <br />
              <br />
            </div>
          </div>
        )}
        {showTablePopUp &&
          (section === "Vendor" ? (
            <CreateVendorPopup
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
            />
          ) : section === "Cafe" ? (
            <CreateCafePopup
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
            />
          ) : section === "drinktypes" ? (
            <CreateDrinkPopup
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
            />
          ) : section === "Menus" ? (
            <CreateMenuPopup
              cafeId={paramId}
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
            />
          ) : section === "coupons" ? (
            <CreateCouponPopup
              // cafeId={paramId}
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
            />
          ) : null)}
      </div>
    );
  }
);
CustomTable.propTypes = {
  urlParam: PropTypes.string,
  viewPopUp: PropTypes.bool,
  toggleButton: PropTypes.oneOfType([PropTypes.object]),
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  section: PropTypes.string,
};
CustomTable.defaultProps = {
  viewPopUp: false,
  toggleButton: { show: false, field: "none" },
  deleteCondition: { checkCondition: false, apiUrl: "none" },
  section: "User",
  urlParam: null,
};
export default CustomTable;
