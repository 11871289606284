
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { getCookies } from '../../hooks/useCookies';
import styles from './ToggleButton.module.scss';
import envValues from '../../enviornment';

function ToggleButtonOnOff({ value, id ,isDisabled}) {
  const [isOff, setIsOff] = useState(false);

  useEffect(() => {
    setIsOff(value);
  }, [value]);

  const handleButtonClick = () => {
    if (isDisabled) return;
  
    const token = getCookies('Token');
    if (!token) {
      alert('User is not authenticated. Please log in.');
      return;
    }
  
    confirmAlert({
      title: 'Confirm to change status',
      message: 'Are you sure you want to change the status?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const newStatus = !isOff; // Toggle the state
            const headers = {
              'Content-Type': 'application/json; charset=utf-8',
            };
  
            const body = { drinkId: id, active: newStatus };

  
            axios
              .post(`${envValues.REACT_APP_API_ENDPOINT}/menus/toggle-change`, body, { headers })
              .then(() => {
                setIsOff(newStatus); // Update state only if the request is successful
              })
              .catch((error) => {
                console.error('Error changing status:', error);
                alert('Failed to change status. Please try again.');
              });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };
  
  return (
    <div className="col">
      <label className={styles.switch}>
        <input type="checkbox" checked={isOff} onClick={handleButtonClick}  disabled={isDisabled} />
        <span className={styles.slider} />
      </label>
    </div>
  );
}

ToggleButtonOnOff.propTypes = {
  value: PropTypes.bool,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
};

ToggleButtonOnOff.defaultProps = {
  value: false,
  id: null,
  isDisabled: false,
};
export default ToggleButtonOnOff;
